import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { expertiseOptions } from "../../Constants/DummyInfo";
import { Anta } from "../../Constants/Fonts";
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {motion} from 'framer-motion'
import { makeAuthenticatedRequest } from "../../util/Firebase";

import { validateEmail } from "../../util/Checker";

export default function ExpertForm({updateAgent, agent, updateComplete, disabled=false}){

    const fields = [
        {name: "name", title: "Name", props:{required:true, placeholder: "John doe", disabled:disabled}, size: 6},
        {name: "title", title: "Title", props:{required:true, placeholder: "Incident Response Specialist", disabled:disabled}, size: 6},
        {name: "about", title: "About", props:{multiline:true, required:true, rows:5, placeholder: "A little bio about your expert", disabled:disabled}, size:12}
    ]

    const theme = useTheme()

    //index of current choice
    const [currentAudio, setCurrentAudio] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [audio, setAudio] = useState(null)
    const [searchString, setSearchString] = useState("")
    const [voiceOptions, setVoiceOptions] = useState([])   

    useEffect(()=>{
        if(!updateComplete)
            return

        let complete = true
        if(!agent.email || !validateEmail(agent.email)){
            updateComplete(false)
            return;
        }
        //see if there are any incomplete fields
        for(const f of fields){
            if(f.props.required && !agent[f.name]){
                updateComplete(false)
                return;
            }
        }

        complete = Boolean(complete && agent.expertise)

        updateComplete(complete)
    },[agent])

    useEffect(()=>{
            makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/elevenlabs/voices`).then(async (response) => {
                if(!response.ok){
                    throw new Error("unable to get voices options")
                }
                
                const body = await response.json()
                setVoiceOptions(body.voices)
                console.log('voices', body.voices)
            }).catch((e) => {
                console.error('error', e)
            })
    }, [])


    const getAudioBorder = (index) => {
        if(!agent.voice){
            return index === 0 ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent'
        }
        return voiceOptions[index].voice_id === agent.voice ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent'
    }

    const handlePlay = (index) => {
        const voiceObj = voiceOptions[index]
        console.log('play');
        console.log('previewUrl', voiceObj.preview_url);

        // Create an Audio object if not already created
        if (audio) {
            // Pause and reset if the audio object already exists
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false)
        }

        // Create a new audio object
        const newAudio = new Audio(voiceObj.preview_url);

        // Set up event listener for when the audio finishes playing
        newAudio.onended = () => {
            console.log('Audio has finished playing');
            setIsPlaying(false)
        };

        //set audio in state to control it later
        setAudio(newAudio);

        // Play the audio
        newAudio.play();
        setCurrentAudio(index)
        setIsPlaying(true)
        
    };

    return(
        <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        {!disabled && <Typography align="center" sx={{fontFamily: Anta.fontFamily, marginBottom:'0.5rem'  }}>Enter In Your Information for Your Custom Expert Here</Typography>}
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField label="Contact Email" value={agent.email || ""} onChange={(e) => updateAgent("email", e.target.value)} fullWidth required
                error={agent.email && !validateEmail(agent.email)}/>
            </Grid>
            {fields.map((f, index) => (
                <Grid item xs={12} md={f.size || 12}>
                    <TextField {...f.props} label={f.title || f.name} value={agent[f.name]}  onChange={(e) => updateAgent(f.name, e.target.value)} fullWidth/>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Autocomplete
                options={expertiseOptions}
                getOptionLabel={(option) => option.name}
                value={agent.expertise}
                required
                disabled={disabled}
                onChange={(e, v) => updateAgent('expertise', v)}
                renderInput={(params) => 
                <TextField {...params} label="Expertise" placeholder="Cybersecurity Expert" required/>}
                />
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
            multiple
            freeSolo
            value={agent.keywords || []}
            options={[]}
            inputValue={searchString}
            onChange={(e, v) => {setSearchString(""); updateAgent('keywords', v)}}
            onInputChange={(e) => setSearchString(e.target.value)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Keywords"
                    placeholder="some quick keywords about your expert"
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position="end" sx={{mr:1}}>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    if (searchString) {
                                        console.log('searchString', searchString)
                                        updateAgent('keywords', [...(agent.keywords || []), searchString]);
                                        setSearchString("")
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                        </>
                    ),
                    }}
                />
            )}
        />
        <Grid item container xs={12} spacing={1} sx={{marginTop:'0.5rem'}}>
    {voiceOptions.map((v, index) => (
        <Grid 
            item 
            container 
            xs={6} 
            md={4} 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                flexDirection: 'column' 
            }}
            key={index}
        >
            <motion.div
                whileHover={{ backgroundColor: 'rgba(128, 128, 128, 0.2)' }} // Grey background on hover
                animate={{
                    border: getAudioBorder(index)
                }}
                transition={{ duration: 0.3 }} // Smooth transition effect
                style={{
                    borderRadius: '8px', // Optional for rounded corners
                    width:'100%',
                    display:'flex',
                    cursor:'pointer',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                }}
                onClick={() => updateAgent('voice', voiceOptions[index].voice_id)}
            >
                <Typography>{v.name}</Typography>
                <IconButton onClick={() => handlePlay(index)}>
                    {currentAudio !== index || !isPlaying ? (
                        <PlayCircleIcon />
                    ) : (
                        <StopCircleIcon />
                    )}
                </IconButton>
            </motion.div>
        </Grid>
    ))}
</Grid>

        </Grid>
        </Grid>
        </Box>
    )
}