import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CustomButton from "./CustomButton";

export default function ErrorModal({error, onClose}) {
    const handleClose = (e) => {
        if (e) {
            e.preventDefault();
        }
        onClose();
    };

    return (
        <Dialog 
            open={Boolean(error)} 
            onClose={handleClose}
        >
            <DialogTitle>An Error has occurred</DialogTitle>
            <DialogContent>
                <DialogContentText>{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}