import { PlayArrow, Stop } from "@mui/icons-material";
import { CircularProgress, IconButton, useTheme } from "@mui/material";
import React, { useRef, useState, useEffect, useCallback } from "react";
import ErrorModal from "./ErrorModal";
import { makeAuthenticatedRequest } from "../../util/Firebase";

const TextToSpeechPlayer = ({textToPlay, voiceId, controls=false, autoplay=true, muted=false}) => {
    const audioRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [cachedAudio, setCachedAudio] = useState(null);
    const [currentText, setCurrentText] = useState("");
    const [audioLoading, setAudioLoading] = useState(false)

    const [error, setError] = useState("")
    const theme = useTheme();

    //catch when done playing audio
    useEffect(() => {
        if (!audioRef.current) return;
      
        const handleAudioEnd = () => {
          setPlaying(false);
        };
      
        const currentAudio = audioRef.current;
        currentAudio.addEventListener("ended", handleAudioEnd);
      
        return () => {
          currentAudio.removeEventListener("ended", handleAudioEnd);
        };
    }, []);

    useEffect(()=>{
        if(textToPlay && autoplay)
            handleStreamAudio()
    }, [textToPlay])

    const handleStreamAudio = useCallback(async () => {

        // If audio is already cached and text hasn't changed, just play the cached audio
        try{
            if (cachedAudio && currentText === textToPlay) {
                const audioElement = audioRef.current;
                if (audioElement) {
                    if(audioElement.src != cachedAudio){
                        audioElement.src = cachedAudio;
                    }
                    audioElement.play();
                    setPlaying(true);
                    return;
                }
            }
    
            //if no text do nothing
            if(!Boolean(textToPlay)){
                return
            }
    
            const audioElement = audioRef.current;
            if (!audioElement) return;
    
            const mediaSource = new MediaSource();
            audioElement.src = URL.createObjectURL(mediaSource);
    
            mediaSource.addEventListener("sourceopen", async () => {
                try{
                    let fileType, mimeType

                    if(MediaSource.isTypeSupported('audio/mpeg')){
                        fileType = 'mp3'
                        mimeType = 'audio/mpeg'
                    }else{
                        fileType = 'webm'
                        mimeType = 'audio/webm; codec="opus"'
                    }
                    const sourceBuffer = mediaSource.addSourceBuffer(mimeType);
                    setAudioLoading(true)
                    let response
                    try{
                        const body = {
                            input: textToPlay,
                            output_format: fileType
                        }

                        if(voiceId){
                            body.voice_id = voiceId
                        }

                        response = await makeAuthenticatedRequest(`${process.env.REACT_APP_WHISPER_BACKEND}/elevenlabs/text2speech`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body),
                        });
                    }catch(e){
                        throw new Error("failed to connect to text to speech, please try again later")
                    }
                    
                    setAudioLoading(false)
                    console.log('response status', response.ok)
                    if(!response.ok){
                        throw new Error("failed to connect to text to speech, please try again later")
                    }

                    const reader = response.body.getReader();
                    const chunks = [];
                    let firstChunk = true;
                    setPlaying(true);
                    setCurrentText(textToPlay);
        
                    const readChunks = async () => {
                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) {
                                const audioBlob = new Blob(chunks, { type: mimeType });
                                const audioUrl = URL.createObjectURL(audioBlob);
                                setCachedAudio(audioUrl);
                
                                // Explicitly handle the end of audio
                                audioElement.addEventListener('ended', () => {
                                    setPlaying(false);
                                }, { once: true });
                
                                // End the MediaSource stream
                                if (mediaSource.readyState === "open") {
                                    mediaSource.endOfStream();
                                }
                                break;
                            }
                            if (value) {
                                chunks.push(value);
                                sourceBuffer.appendBuffer(value);
                                await new Promise(resolve =>
                                    sourceBuffer.addEventListener('updateend', resolve, { once: true })
                                );
                                // Start playback as soon as the first chunk is buffered
                                if (firstChunk) {
                                    audioElement.play();
                                    firstChunk = false;
                                }
                            }
                        }
                    };
                    await readChunks();
                }catch(e){
                    if(e.name === 'NotSupportedError'){
                        setError("Text to Speech requires your browser to support MP3s.  Please use a browswer with support for MP3 files.")
                    }else{
                        setError(e.message)
                    }
                }finally{
                    setAudioLoading(false)
                }
                
                
            });
        }catch(e){
            setError(e.message)
        }
        
    }, [textToPlay, cachedAudio, currentText]);

    const handleStopAudio = () => {
        const audioElement = audioRef.current;
        if (audioElement) {
            audioElement.pause();
            setPlaying(false);
        }
    };

    // Clean up blob URLs to prevent memory leaks
    useEffect(() => {
        return () => {
            if (cachedAudio) {
                URL.revokeObjectURL(cachedAudio);
            }
        };
    }, [cachedAudio]);

    return (
        <div>
            <ErrorModal error={error} onClose={setError}/>
            {
            audioLoading? 
            <CircularProgress size={24}/>
            :
            playing ?
            <IconButton
                onClick={(e) => {e.preventDefault(); handleStopAudio()}}
                sx={{color: theme.palette.primary.main}}
            >
                <Stop/>
            </IconButton>
            :
            <IconButton
                onClick={(e) => {e.preventDefault(); handleStreamAudio()}}
                sx={{color: theme.palette.primary.main}}
                disabled={playing}
            >
                <PlayArrow/>
            </IconButton>
            }
            <audio ref={audioRef} controls={controls} muted={muted} />
        </div>
    );
};

export default TextToSpeechPlayer;